import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { AssessmentAccessType } from "@pulsemarket/api-client";
import {
  getFoldersByRegulation as getFolderByRegulation,
  getRegulationQuestionnaires,
  getRegulations,
  getRegulationSurveys,
} from "shared/client";

export function useGetRegulationQuestionnaires({
  assessmentAccessType,
  regulationId,
}: {
  assessmentAccessType: AssessmentAccessType;
  regulationId: string;
}) {
  return useQuery(
    queryKeys.regulationQuestionnaires(assessmentAccessType, regulationId),
    () => getRegulationQuestionnaires(assessmentAccessType, regulationId),
    {
      // https://dev.azure.com/PulseMarketplace/pulse-marketplace/_workitems/edit/7221
      // Refetch on mount to ensure the data is always up to date after changing the response's status
      refetchOnMount: "always",
    }
  );
}

export function useGetRegulationSurveys({
  regulationId,
}: {
  regulationId: string;
}) {
  return useQuery(
    queryKeys.regulationSurveys(regulationId),
    () => getRegulationSurveys(regulationId),
    {
      ...longCachedQueryOptions,
    }
  );
}

export function useGetFolderByRegulation(regulationId: string) {
  return useQuery(
    queryKeys.regulationFolders(regulationId),
    () => getFolderByRegulation(regulationId),
    {
      ...longCachedQueryOptions,
    }
  );
}

export function useGetRegulations() {
  return useQuery(queryKeys.regulations, getRegulations, {
    ...longCachedQueryOptions,
  });
}
