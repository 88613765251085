import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { FolderAPIItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { deleteAsset } from "shared/client";
import { ContainerType } from "shared/model";

export default function useDeleteAsset() {
  // This hook could be rewritten to use a folder instead of a folderId, so we could reach its parent through the object, not from a context
  const client = useQueryClient();
  const { searchOptions } = useAssetContext();

  const { data: ownCompany } = useOwnCompany();

  return useMutation(
    (assetId: string) => {
      return deleteAsset(ownCompany!.id, assetId, ContainerType.Assets);
    },
    {
      mutationKey: mutationKeys.deleteAsset,
      // When mutate is called:
      onMutate: async (assetId: string) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Snapshot the previous values
        const previousSearchResult = client.getQueryData<FolderAPIItem[]>(
          queryKeys.assetRepositorySearch(searchOptions)
        );

        // Optimistically update to the new value
        if (previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            previousSearchResult.filter(
              (item) => item.id !== searchOptions.folderId
            )
          );
        }

        return { previousSearchResult };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            context.previousSearchResult
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(
          queryKeys.assetRepositorySearch(searchOptions)[0]
        );
      },
    }
  );
}
