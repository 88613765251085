import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { hiddenScrollbarStyles } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { extend, media, stylesheet } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

export const sideBarWidth = px(85);

const closeIconContainerClass: NestedCSSProperties = {
  marginLeft: "16px",
  width: "40px",
  justifyContent: "center",
  alignItems: "center",
  transform: "scale(1.2, 1.2)",
  transitionDelay: "0.05s",
  transition: "0.2s",
  padding: "5px",
  borderRadius: "10px",
  $nest: {
    "&:hover": {
      backgroundColor: primaryColors.PURPLE2,
      color: primaryColors.WHITE,
    },
  },
};

const sideMenuStatusClass: NestedCSSProperties = {
  transition: "0.2s",
};

const menuWrapperClass: NestedCSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};

export default stylesheet({
  pulseIcon: {
    height: "45%",
  },
  itemIconClass: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20px",
  },
  itemIconHeaderClass: {
    height: "100%",
    width: "40px",
  },
  fixedIconSize: {
    width: "13px",
  },
  divider: {
    marginBottom: "8px",
    marginTop: "auto",
    background: neutralColors.GRAY3,
    opacity: 0.15,
  },
  groupContainer: {
    ...hiddenScrollbarStyles,
    maxHeight: "60%",
    overflowY: "auto",
  },
  itemLabelClass: {
    whiteSpace: "nowrap",
    marginLeft: "15px",
    marginRight: "32px",
  },
  itemHeaderLabelClass: {
    textTransform: "uppercase",
  },
  headerClass: {
    height: "97px!important",
    width: "100%",
  },
  sideMenuClass: {
    backgroundColor: primaryColors.PURPLE3,
    position: "relative",
    color: neutralColors.GRAY3,
    height: "100vh",
    boxShadow: "-5px 0 5px -5px rgb(0 0 0 / 25%)",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    overflow: "hidden",
  },
  subMenuItem: {
    backgroundColor: primaryColors.WHITE,
    color: primaryColors.BLACK,
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.HOVER,
      },
    },
  },
  subMenuTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    height: "58px",
  },
  subMenuTitle: {
    display: "flex",
    alignItems: "center",
    ...typography.TITLE2,
  },
  menuItemClass: {
    height: "58px",
    zIndex: 2,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    lineHeight: "20px",
    fontSize: "1rem",
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.PURPLE2_HOVER,
        color: primaryColors.WHITE,
        borderLeft: "3px solid #dde2ff",
      },
    },
  },
  activeMenuItem: {
    backgroundColor: primaryColors.PURPLE2,
    color: primaryColors.WHITE,
    borderLeft: "3px solid #dde2ff",
  },
  burgerButtonContainer: {
    height: "97px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
  },
  burgerButton: {
    width: "48px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    paddingBottom: "8px",
    borderRadius: "8px",
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.PURPLE2,
        color: primaryColors.WHITE,
      },
    },
  },
  iconContainerClass: {
    display: "flex",
    width: "23px",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginLeft: "32px",
  },
  pulseIconContainerClass: {
    display: "flex",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    height: "97px",
    marginLeft: "32px",
    ...extend(media({ minWidth: 1920 }, { marginLeft: "28px" })),
  },
  menuPulseIconClass: {
    height: "58px",
    zIndex: 2,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    lineHeight: "20px",
    ...extend(media({ minWidth: 1920 }, { justifyContent: "start" })),
  },
  menuWrapperClassOpen: {
    ...menuWrapperClass,
  },
  menuWrapperClassClose: {
    ...menuWrapperClass,
  },
  sideMenuStatusClassOpen: {
    ...sideMenuStatusClass,
    width: "auto",
    maxWidth: "268px",
  },
  sideMenuStatusClassClose: {
    ...sideMenuStatusClass,
    width: sideBarWidth,
  },
  closeIconContainerClassOpen: {
    ...closeIconContainerClass,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  closeIconContainerClassClose: {
    ...closeIconContainerClass,
    display: "none",
  },
});
