const mutationKeys = {
  login: ["login"] as const,
  logout: ["logout"] as const,
  renewToken: ["renewToken"] as const,
  verifyEmail: ["verifyEmail"] as const,
  register: ["register"] as const,
  resendVerifyEmail: ["resendVerifyEmail"] as const,
  forgotPassword: ["forgotPassword"] as const,
  resetPassword: ["resetPassword"] as const,
  createDataRoom: ["createDataRoom"] as const,
  deleteDataRoom: ["deleteDataRoom"] as const,
  updateDataRoom: ["updateDataRoom"] as const,
  recordDataRoomVisit: ["recordDataRoomVisit"] as const,
  createCompanyList: ["createCompanyList"] as const,
  addCompanyToList: ["addCompanyToList"] as const,
  deleteCompanyFromList: ["deleteCompanyFromList"] as const,
  deleteCompanyList: ["deleteCompanyList"] as const,
  renameCompanyList: ["renameCompanyList"] as const,
  createFolder: ["createFolder"] as const,
  renameFolder: ["renameFolder"] as const,
  renameAsset: ["renameAsset"] as const,
  moveFolder: ["moveFolder"] as const,
  moveAsset: ["moveAsset"] as const,
  createAssetLabel: ["createAssetLabel"] as const,
  updateAssetLabel: ["updateAssetLabel"] as const,
  deleteAssetLabel: ["deleteAssetLabel"] as const,
  addLabelToAssets: ["addLabelToAssets"] as const,
  removeLabelFromAssets: ["removeLabelFromAssets"] as const,
  changeAssetType: ["changeAssetType"] as const,
  cloneAnswerAttachment: ["cloneAnswerAttachment"] as const,
  addVideoToDataRoom: ["addVideoToDataRoom"] as const,
  deleteVideo: ["deleteVideo"] as const,
  deleteAnswerAttachment: ["deleteAnswerAttachment"] as const,
  downloadZip: ["downloadZip"] as const,
  downloadFiles: ["downloadFiles"] as const,
  deleteFolder: ["deleteFolder"] as const,
  deleteAsset: ["deleteAsset"] as const,
  uploadAsset: ["uploadAsset"] as const,
  uploadDataRoomAsset: ["uploadDataRoomAsset"] as const,
  cloneDataRoomAssets: ["cloneDataRoomAssets"] as const,
  uploadSupplierAsset: ["uploadSupplierAsset"] as const,
  cloneSupplierAssets: ["cloneSupplierAssets"] as const,
  uploadSupplierProductAsset: ["uploadSupplierProductAsset"] as const,
  cloneSupplierProductAssets: ["cloneSupplierProductAssets"] as const,
  uploadContractAttachment: ["uploadContractAttachment"] as const,
  uploadTemplateFileToMatrix: ["uploadTemplateFileToMatrix"] as const,
  cloneTemplateFileToMatrix: ["cloneTemplateFileToMatrix"] as const,
  cloneContractAttachments: ["cloneContractAttachments"] as const,
  uploadAnswerAttachment: ["uploadAnswerAttachment"] as const,
  updateCompany: ["updateCompany"] as const,
  createRFP: ["createRFP"] as const,
  updateRFP: ["updateRFP"] as const,
  updateWeightsInModules: ["updateWeightsInModules"] as const,
  updateRFPEvaluationType: ["updateRFPEvaluationType"] as const,
  updateRFPScoringSettings: ["updateRFPScoringSettings"] as const,
  createRFPScoreSettingGroup: ["createRFPScoreSettingGroup"] as const,
  setRFPScoreSettingGroup: ["setRFPScoreSettingGroup"] as const,
  deleteScoreSettingsGroup: ["deleteScoreSettingsGroup"] as const,
  sendRFP: ["sendRFP"] as const,
  startAnalyticalReview: ["startAnalyticalReview"] as const,
  markDocumentToBeEvaluated: ["markDocumentToBeEvaluated"] as const,
  selectWinnerResponse: ["selectWinnerResponse"] as const,
  togglePassportVisibility: ["togglePassportVisibility"] as const,
  deleteRFP: ["deleteRFP"] as const,
  saveRFPRecipients: ["saveRFPRecipients"] as const,
  saveRfxIndividualRecipients: ["saveRfxIndividualRecipients"] as const,
  createModule: ["createModule"] as const,
  editModule: ["editModule"] as const,
  deleteModule: ["deleteModule"] as const,
  createModuleQuestionnaire: ["createQuestionnaire"] as const,
  editModuleQuestionnaire: ["editQuestionnaire"] as const,
  deleteModuleQuestionnaire: ["deleteQuestionnaire"] as const,
  createQuestionnaireQuestion: ["createQuestion"] as const,
  editQuestionnaireQuestion: ["editQuestion"] as const,
  deleteQuestionnaireQuestion: ["deleteQuestion"] as const,
  createRFPResponse: ["createRFPResponse"] as const,
  createQuestionnaireAnswer: ["createAnswer"] as const,
  editQuestionnaireAnswer: ["editAnswer"] as const,
  deleteQuestionnaireAnswer: ["deleteAnswer"] as const,
  updateAnswerScore: ["updateAnswerScore"] as const,
  editRfpResponse: ["editRfpResponse"] as const,
  inviteCompanyMembers: ["inviteCompanyMembers"] as const,
  resendMemberInvitation: ["resendMemberInvitation"] as const,
  inviteToDataRoom: ["inviteToDataRoom"] as const,
  updateUser: ["updateUser"] as const,
  createGroup: ["createGroup"] as const,
  updateGroup: ["updateGroup"] as const,
  deleteGroup: ["deleteGroup"] as const,
  inviteToGroup: ["inviteToGroup"] as const,
  requestAccessToGroup: ["requestAccessToGroup"] as const,
  setGroupInvitationStatus: ["setGroupInvitationStatus"] as const,
  leaveGroup: ["leaveGroup"] as const,
  createGroupVideo: ["createGroupVideo"] as const,
  updateGroupVideo: ["updateGroupVideo"] as const,
  deleteGroupVideo: ["deleteGroupVideo"] as const,
  uploadGroupAttachment: ["uploadPostAttachment"] as const,
  cloneGroupAttachments: ["cloneGroupAttachments"] as const,
  deleteGroupAttachment: ["deleteGroupAttachment"] as const,
  createPost: ["createPost"] as const,
  updatePost: ["updatePost"] as const,
  deletePost: ["deletePost"] as const,
  uploadPostAttachment: ["uploadPostAttachment"] as const,
  clonePostAttachments: ["clonePostAttachments"] as const,
  deletePostAttachment: ["deletePostAttachment"] as const,
  createPostVideo: ["createPostVideo"] as const,
  updatePostVideo: ["updatePostVideo"] as const,
  deletePostVideo: ["deletePostVideo"] as const,
  likePost: ["likePost"] as const,
  addComment: ["addComment"] as const,
  updateComment: ["updateComment"] as const,
  deleteComment: ["deleteComment"] as const,
  sendAnswerMessages: ["sendAnswerMessages"] as const,
  inviteToTakePassport: ["inviteToTakePassport"] as const,
  cloneAssetsToFolder: ["cloneAssetsToFolder"] as const,
  createCompany: ["createCompany"] as const,
};

export default mutationKeys;
