import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { useSnackBar } from "contexts/Snackbar/SnackbarProvider";
import useDeleteAsset from "hooks/queries/useDeleteAsset";
import { ApiError, getAssetBlobUrls } from "shared/client";

export default function useDownloadAssets() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: deleteAsset } = useDeleteAsset();

  const { showSnackBar } = useSnackBar();

  const downloadAssets = useCallback(
    async ({
      assetIds,
      openInNewTab = false,
      fileNamePrefix,
    }: {
      assetIds: string[];
      openInNewTab?: boolean;
      fileNamePrefix?: string;
    }) => {
      const assetsNotCachedOrStale = assetIds?.filter((assetId) => {
        const queryKeyCachedAt = queryClient.getQueryState(
          queryKeys.blobUrl(assetId)
        )?.dataUpdatedAt;

        // Cache is valid for 10 seconds
        const isStale =
          !queryKeyCachedAt ||
          (queryKeyCachedAt &&
            new Date().getTime() - queryKeyCachedAt > 10 * 1000);

        return !!isStale && assetId;
      });

      // Fetch the blob urls for the assets that are not cached and set them in the cache
      if (!!assetsNotCachedOrStale?.length) {
        setIsLoading(true);
        try {
          const data = await getAssetBlobUrls(
            assetsNotCachedOrStale,
            openInNewTab,
            fileNamePrefix
          );
          if (data) {
            data.forEach((asset) => {
              queryClient.setQueryData(
                queryKeys.blobUrl(asset.assetId),
                asset.blobUrl
              );
            });
          }
        } catch (error) {
          if (error instanceof ApiError) {
            if (error.status === 403) {
              showSnackBar({
                text: "This file is damaged and can’t be opened.",
                typeColor: "error",
                ...(assetIds.length === 1 && {
                  action: {
                    children: "Delete file",
                    onClick: () => {
                      deleteAsset(assetIds[0]);
                    },
                  },
                }),
              });
              // Unauthorized error is thrown when the asset cannot been queried by the user. This can happen when the asset is deleted by the anti malware service
            } else if (error.status === 401) {
              showSnackBar({
                text: "An error occurred while uploading the file. Please try again",
                typeColor: "error",
              });
              queryClient.invalidateQueries("assetRepositorySearch");
            } else if (error.status === 400) {
              showSnackBar({
                text: "This file is not ready for download yet. Please try downloading again shortly.",
                typeColor: "info",
              });
            }
          }
          return;
        }
        setIsLoading(false);
      }

      // Download each files separately
      assetIds.forEach((asset) => {
        const blobUrl = queryClient.getQueryData(
          queryKeys.blobUrl(asset)
        ) as string;
        window.open(blobUrl);
      });
    },
    [deleteAsset, queryClient, showSnackBar]
  );

  return { downloadAssets, isLoading };
}

/**
 * @deprecated - This is no longer used to fulfil security requirements to minimize the SAS token expiration time
 */
export function usePreDownloadAssets({
  assetIds,
  openInNewTab = false,
}: {
  assetIds: string[];
  openInNewTab?: boolean;
}) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const assetsNotCached = (assetIds || []).filter((assetId) => {
        return !queryClient.getQueryData(queryKeys.blobUrl(assetId)) && assetId;
      });
      setIsLoading(true);
      const data = await getAssetBlobUrls(assetsNotCached, openInNewTab);
      if (data) {
        data.forEach((asset) => {
          queryClient.setQueryData(
            queryKeys.blobUrl(asset.assetId),
            asset.blobUrl
          );
        });

        setIsLoading(false);
      }
    })();
  }, [assetIds, openInNewTab, queryClient]);

  const downloadAssets = useCallback(async () => {
    let assetsNotCached: string[];
    do {
      assetsNotCached = (assetIds || []).filter((assetId) => {
        return !queryClient.getQueryData(queryKeys.blobUrl(assetId)) && assetId;
      });
      await wait(1000);
    } while (assetsNotCached.length > 0);

    assetIds.forEach((asset) => {
      const blobUrl = queryClient.getQueryData(queryKeys.blobUrl(asset));
      window.open(blobUrl as string);
    });
  }, [assetIds, queryClient]);

  return { downloadAssets, isLoading };
}

function wait(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
