import React, { MouseEvent, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { Divider, MenuItem } from "@mui/material";
import { ROUTES } from "@pulsemarket/constants";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip, { purpleTooltipProps } from "components/ui/Tooltip";
import { usePublicVisitorContext } from "contexts/PublicVisitorContext/PublicVisitorContext";
import { useGetAuthToken } from "hooks/queries/useAuth";
import { DEFAULT_PAGESIZE } from "hooks/queries/useMySuppliers";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import useGetRegulationMenuItem from "layout/SideMenu/useGetRegulationMenuItem";
import { getAssetLabels, getMySuppliers } from "shared/client";
import { clickableClass } from "shared/styles/classes";
import { classes } from "typestyle";

import styles from "./SideMenuStyles";

export interface SideMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
}

export enum MenuItemGroup {
  Resources = "Resources",
  Settings = "Settings",
}

export type SubMenuItemProps = {
  title: string;
  items: {
    onClick: () => void;
    label: string;
  }[];
};

export enum SideMenuItemName {
  Dashboard = "Dashboard",
  Regulations = "Regulations",
  SupplierProfiling = "Supplier Profiling",
  ValueChain = "Value Chain",
  PulsePassport = "Pulse Passport",
  Assets = "Assets",
  DataRoom = "Data Room",
  SupplierSearch = "Supplier Search",
  PulsePage = "Pulse Page",
  CompanyAccount = "Company Account",
  DataManagement = "Data Management",
  MyAccount = "My Account",
}

export type SideMenuItemProps = JSX.IntrinsicElements["div"] & {
  group: MenuItemGroup;
  label: SideMenuItemName;
  icon?: JSX.Element;
  onClick?: () => void;
  className?: string;
  showLabel?: boolean;
  isHidden?: boolean;
  subMenu?: SubMenuItemProps;
  isActive?: boolean;
};

function FullWidthDivider() {
  return <Divider variant="fullWidth" className={styles.divider} />;
}

const sidemenuItemRouteMap: Record<SideMenuItemName, string[]> = {
  [SideMenuItemName.Dashboard]: ["/"],
  [SideMenuItemName.Regulations]: ["/regulations"],
  [SideMenuItemName.SupplierProfiling]: [ROUTES.MY_SUPPLIERS],
  [SideMenuItemName.ValueChain]: [
    ROUTES.RFP_MANAGEMENT_FOR_BUYERS,
    ROUTES.RFP_MANAGEMENT_RFP,
    ROUTES.RFP_MANAGEMENT_DDQ,
    ROUTES.RFP_MANAGEMENT_QTP,
    ROUTES.RFP_MANAGEMENT_RFI,
    ROUTES.RFP_MANAGEMENT_FOR_SUPPLIERS,
    ROUTES.RFP_MANAGEMENT_RFP_RESPONSES,
    ROUTES.RFP_MANAGEMENT_DDQ_RESPONSES,
    ROUTES.RFP_MANAGEMENT_QTP_RESPONSES,
    ROUTES.RFP_MANAGEMENT_RFI_RESPONSES,
  ],
  [SideMenuItemName.PulsePassport]: [ROUTES.PASSPORT_MANAGEMENT],
  [SideMenuItemName.Assets]: ["/assets"],
  [SideMenuItemName.DataRoom]: ["/datarooms"],
  [SideMenuItemName.SupplierSearch]: ["/vendorsearch"],
  [SideMenuItemName.PulsePage]: ["/pulsepage"],
  [SideMenuItemName.CompanyAccount]: ["/company"],
  [SideMenuItemName.MyAccount]: ["/profile"],
  [SideMenuItemName.DataManagement]: [ROUTES.DATA_MANAGEMENT.root],
};

const SideMenuItem = ({
  showLabel,
  onClick,
  subMenu,
  isActive,
  ...item
}: SideMenuItemProps) => {
  // This is the component rendered in the tooltip body on hover
  const TooltiptTitle = subMenu ? (
    <div>
      <div className={styles.subMenuTitleContainer}>
        <span className={styles.subMenuTitle}>{subMenu.title}</span>
      </div>
      {subMenu.items.map((subItem, i) => (
        <div key={subItem.label + i}>
          <MenuItem
            className={styles.subMenuItem}
            onClick={(e) => {
              e.stopPropagation();
              subItem.onClick();
            }}
          >
            {subItem.label}
          </MenuItem>
        </div>
      ))}
    </div>
  ) : (
    item.label
  );

  return !item.isHidden ? (
    <Tooltip
      title={TooltiptTitle}
      placement="right"
      disableHoverListener={showLabel && !subMenu}
      {...purpleTooltipProps}
      noPadding={!!subMenu}
      hideArrow={!!subMenu}
      hideOnBlur={false}
    >
      <div
        {...item}
        onClick={onClick}
        className={classes(
          isActive && styles.activeMenuItem,
          styles.menuItemClass,
          item.className
        )}
      >
        <div className={styles.iconContainerClass}>{item.icon}</div>

        {showLabel && (
          <span className={styles.itemLabelClass}>{item.label}</span>
        )}
      </div>
    </Tooltip>
  ) : (
    <React.Fragment />
  );
};

export default function SideMenu({
  isOpen,
  toggleMenu,
  closeMenu,
}: SideMenuProps) {
  // Utility hooks
  const history = useHistory();
  const client = useQueryClient();

  // Feature flags
  const { data: dataRoomEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataroom,
  });
  const { data: dataManagementEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataManagement,
  });
  const { data: suppliersEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.mySuppliers,
  });
  const passportEnabled = usePassportFeatureEnabled();

  // Auth and context hooks
  const { data, isLoading } = useGetAuthToken();

  const { viewedDataRoomId, viewedCompanyId: viewedDataRoomCompanyId } =
    usePublicVisitorContext();

  // Query hooks
  const {
    data: ownCompany,
    isLoading: companyLoading,
    isError: companyError,
  } = useOwnCompany();

  const activeMenuItem = useMemo(() => {
    const mainPath = "/" + history.location.pathname.split("/")[1];

    if (mainPath === "/") {
      return SideMenuItemName.Dashboard;
    }

    const menuItemActive = Object.entries(sidemenuItemRouteMap).find(
      ([_, routes]) => routes.includes(mainPath)
    )?.[0];

    return menuItemActive || null;
  }, [history.location.pathname]);

  const regulationMenuItem = useGetRegulationMenuItem({ isOpen });

  const displayItems: JSX.Element[] = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const privateItems: SideMenuItemProps[] = [
      {
        group: MenuItemGroup.Resources,
        label: SideMenuItemName.Dashboard,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/dashboard_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push("/"),
      },
      regulationMenuItem,
      ...((suppliersEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: SideMenuItemName.SupplierProfiling,
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/supplier_management_icon.svg"
                  className={styles.itemIconClass}
                />
              ),
              onMouseEnter: () => {
                client.prefetchQuery(
                  queryKeys.mySuppliers(ownCompany!.id),
                  () => getMySuppliers({ limit: DEFAULT_PAGESIZE, offset: 0 })
                );
              },
              onClick: () => history.push(ROUTES.MY_SUPPLIERS),
            },
          ]
        : []) as SideMenuItemProps[]),
      {
        group: MenuItemGroup.Resources,
        label: SideMenuItemName.ValueChain,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/proposals_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(ROUTES.RFP_MANAGEMENT_FOR_BUYERS),
      },
      ...((passportEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: SideMenuItemName.PulsePassport,
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/certifications_icon.svg"
                  className={styles.itemIconClass}
                />
              ),
              onClick: () => history.push(ROUTES.PASSPORT_MANAGEMENT),
            },
          ]
        : []) as SideMenuItemProps[]),
      {
        group: MenuItemGroup.Resources,
        label: SideMenuItemName.Assets,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/assets_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(`/assets/${ownCompany?.assetRepositoryId}`),
        onMouseEnter: () => {
          client.prefetchQuery(queryKeys.assetLabels, () =>
            getAssetLabels(ownCompany!.id)
          );
        },
      },
      ...((dataRoomEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              label: SideMenuItemName.DataRoom,
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data-room.svg"
                  className={styles.itemIconClass}
                />
              ),
              onClick: () => history.push("/datarooms"),
            },
          ]
        : []) as SideMenuItemProps[]),
      {
        group: MenuItemGroup.Resources,
        label: SideMenuItemName.SupplierSearch,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/vendor_search_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push("/vendorsearch/suppliersearch"),
        isHidden: true,
      },
      {
        group: MenuItemGroup.Resources,
        isHidden: !ownCompany || !!companyLoading || !!companyError,
        label: SideMenuItemName.PulsePage,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/pulse_page_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(`/pulsepage/${ownCompany!.id}`),
      },
      ...((dataManagementEnabled
        ? [
            {
              group: MenuItemGroup.Resources,
              isHidden: !ownCompany || !!companyLoading || !!companyError,
              label: SideMenuItemName.DataManagement,
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data_management.svg"
                  className={styles.itemIconClass}
                />
              ),
              onClick: () => history.push(ROUTES.DATA_MANAGEMENT.my_data()),
            },
          ]
        : []) as SideMenuItemProps[]),
      {
        group: MenuItemGroup.Settings,
        label: SideMenuItemName.CompanyAccount,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/company_account.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(`/company/edit/info`),
      },
      {
        group: MenuItemGroup.Settings,
        label: SideMenuItemName.MyAccount,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/personal_profile.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(`/profile/edit/info`),
      },
    ];

    const publicItems: SideMenuItemProps[] = [
      ...((dataRoomEnabled && viewedDataRoomId
        ? [
            {
              group: MenuItemGroup.Resources,
              label: SideMenuItemName.DataRoom,
              showLabel: isOpen,
              icon: (
                <SvgIcon
                  src="/icons/data-room.svg"
                  className={styles.itemIconClass}
                />
              ),
              onClick: () => history.push(`/datarooms/${viewedDataRoomId}`),
            },
          ]
        : []) as SideMenuItemProps[]),
      {
        group: MenuItemGroup.Resources,
        label: SideMenuItemName.PulsePage,
        showLabel: isOpen,
        icon: (
          <SvgIcon
            src="/icons/pulse_page_icon.svg"
            className={styles.itemIconClass}
          />
        ),
        onClick: () => history.push(`/pulsepage/${viewedDataRoomCompanyId}`),
      },
    ];

    const items: SideMenuItemProps[] = !!data?.userId
      ? privateItems
      : publicItems;

    return Object.keys(MenuItemGroup).map((g, i) => {
      return (
        <div
          key={g + i}
          className={styles.groupContainer}
          style={
            g === MenuItemGroup.Settings
              ? { marginTop: "auto", height: "auto" }
              : {}
          }
        >
          <React.Fragment key={g + i}>
            <FullWidthDivider />

            {items
              .filter((i) => i !== null && i.group === g)
              .map((item, j) => (
                <SideMenuItem
                  key={item.label + j}
                  {...item}
                  isActive={item.label === activeMenuItem}
                  showLabel={isOpen}
                />
              ))}
          </React.Fragment>
        </div>
      );
    });
  }, [
    isLoading,
    isOpen,
    regulationMenuItem,
    suppliersEnabled,
    passportEnabled,
    dataRoomEnabled,
    ownCompany,
    companyLoading,
    companyError,
    dataManagementEnabled,
    viewedDataRoomId,
    data?.userId,
    history,
    client,
    viewedDataRoomCompanyId,
    activeMenuItem,
  ]);

  const menuItemSelected = (e: MouseEvent) => {
    e.stopPropagation();
    closeMenu();
  };

  const burgerMenuSelected = (e: MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  return (
    <div
      className={classes(
        styles.sideMenuClass,
        isOpen
          ? styles.sideMenuStatusClassOpen
          : styles.sideMenuStatusClassClose
      )}
      onClick={toggleMenu}
    >
      <div
        className={
          isOpen ? styles.menuWrapperClassOpen : styles.menuWrapperClassClose
        }
        onClick={menuItemSelected}
      >
        {isOpen ? (
          <div
            onClick={() => history.push("/")}
            className={classes(styles.menuPulseIconClass, styles.headerClass)}
          >
            <div className={styles.pulseIconContainerClass}>
              <img
                className={styles.pulseIcon}
                src="/img/sidemenu_pulse_logo_light.png"
                alt="pulse icon"
              />
            </div>
            <div
              className={classes(
                clickableClass,
                isOpen
                  ? styles.closeIconContainerClassOpen
                  : styles.closeIconContainerClassClose
              )}
              onClick={menuItemSelected}
            >
              <SvgIcon
                src="/icons/back_icon.svg"
                className={classes(styles.itemIconClass, styles.fixedIconSize)}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={burgerMenuSelected}
            className={styles.burgerButtonContainer}
          >
            <div className={styles.burgerButton}>
              <SvgIcon
                src="/icons/menu_icon.svg"
                className={styles.itemIconClass}
              />
            </div>
          </div>
        )}
        {displayItems}
      </div>
    </div>
  );
}
