import { useContext } from "react";
import { useHistory } from "react-router-dom";

import ConfirmationModalBody from "components/ConfirmationModalBody";
import { TabTitle } from "components/PulsePage/PulsePage";
import ModalContext from "contexts/ModalContext";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useSetGroupInvitationStatus from "hooks/queries/useSetGroupInvitationStatus";
import { GroupInvitationStatus } from "shared/model";

export function LeaveGroupModal({
  activeGroupInvitationId,
}: {
  activeGroupInvitationId: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { data: ownCompany } = useOwnCompany();
  const { mutate: setGroupInvitationStatus } = useSetGroupInvitationStatus();

  const history = useHistory();

  return (
    <ConfirmationModalBody
      title="Leave group"
      action={async () => {
        try {
          if (!ownCompany?.id) {
            return;
          }
          await setGroupInvitationStatus({
            groupInvitationId: activeGroupInvitationId,
            status: GroupInvitationStatus.Left,
          });
          history.push({
            pathname: `/pulsepage/${ownCompany.id}`,
            state: { tabTitle: TabTitle.Groups },
          });
        } catch (_e) {}
      }}
      onClose={closeModal}
    >
      Are you sure you want to leave this group? You may need to ask to join
      again.
    </ConfirmationModalBody>
  );
}
