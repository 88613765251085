import { useCallback, useContext } from "react";

import { CSRDDocumentStatus, RfpResponseDto } from "@pulsemarket/api-client";
import { RfpStatus } from "@pulsemarket/constants";
import PassportInvitationSender from "components/CombinedInvitationSender/PassportInvitationSender";
import GetPassportModal from "components/Passports/Modals/GetPassportModal";
import WelcomePassportModal from "components/Passports/Modals/WelcomePassportModal";
import SimpleModalBody, {
  SimpleModalBodyProps,
} from "components/ui/Modal/SimpleModalBody";
import ModalContext from "contexts/ModalContext";
import { ModalOptions } from "contexts/ModalContext/ModalContext";
import dayjs from "dayjs";
import {
  DynamicRfpResponseStatus,
  PassportDto,
  PassportStatus,
} from "shared/model";

/** Doesn't accept PassportItemDto for flexibility - there's a similar function in the api */
export function getPassportStatus({
  response,
  passport,
}: {
  response?: RfpResponseDto;
  passport?: PassportDto;
}): PassportStatus {
  if (!response) {
    return PassportStatus.None;
  }

  if (response.status === "Draft") {
    return PassportStatus.Draft;
  }

  if (response.status === "InReview") {
    return PassportStatus.InReview;
  }

  if (passport && dayjs(passport.expirationDate).isAfter(dayjs())) {
    return PassportStatus.Submitted;
  }

  return PassportStatus.Expired;
}

/**
 *  todo use + extend with actions
 * Todo expiring statuses https://dev.azure.com/PulseMarketplace/pulse-marketplace/_workitems/edit/6004
 *
 */
export function usePassportStatusConfigurations(): Record<
  PassportStatus,
  {
    dashboardBox?: { text?: React.ReactNode };
    callToActionBox: { title?: string; text?: React.ReactNode; button: string };
  }
> {
  return {
    None: {
      dashboardBox: {
        text: "Unlock your Action Guide and Certificate to turbocharge your company's sustainable growth.",
      },
      callToActionBox: {
        title: "Start your ESG Passport journey",
        text: (
          <>
            Unleash your ESG Passport - your fast track to achieving ESG goals.
            <br />
            Unlock your Action Guide and Certificate to turbocharge your
            company's sustainable growth.
          </>
        ),
        button: "Get Passport",
      },
    },
    Draft: {
      dashboardBox: {
        text: "You can edit your assessment at any time before submitting it.",
      },
      callToActionBox: {
        title: "Your ESG Passport assessment is in Draft",
        text: "You can edit your assessment at any time before submitting it.",
        button: "Go to assessment",
      },
    },
    InReview: {
      dashboardBox: {
        text: "Take your time to review your assessment, and when you're ready and satisfied with your answers, feel free to submit it.",
      },
      callToActionBox: {
        title: "Review and submit your ESG Passport assessment",
        text: "Take your time to review your assessment, and when you're ready and satisfied with your answers, feel free to submit it.",
        button: "Go to assessment",
      },
    },
    Submitted: {
      dashboardBox: {
        text: "Congratulations! You unlocked Pulse ESG tools. Now invite your network of contacts to get on board and align with your company's ESG goals.",
      },
      callToActionBox: {
        title: "ESG Passport in Analysis",
        text: "Our team of experts is reviewing your responses and will be putting together a custom report for you. You'll get an notification as soon as it's complete, so keep an eye out.",
        button: "View assessment",
      },
    },
    Completed: {
      dashboardBox: {
        text: "Congratulations! You unlocked Pulse ESG tools. Now invite your network of contacts to get on board and align with your company's ESG goals.",
      },
      callToActionBox: {
        title: "ESG Passport Completed",
        text: (
          <>
            Congratulations! You've successfully completed your ESG Passport.
            <br />
            This is a significant step in your sustainability journey
          </>
        ),
        button: "View Action Guide",
      },
    },
    CloseToExpire: {
      dashboardBox: {
        text: "Don't let your ESG Passport expire! Renew today for 1 more year - Retake survey and improve score to impress your clients.",
      },
      callToActionBox: {
        title: "Here is a reminder your Pulse Passport is about to expire.",
        text: "Don't miss out on the opportunity to renew. We'll reward you with an extra 365 days of access - giving loads more time to retake your Passport and show your company’s ESG progress. Let's renew your Passport today!",
        button: "Renew Passport",
      },
    },
    Expired: {
      dashboardBox: {
        text: "Your company's Pulse ESG Passport has expired. You can retake your ESG Passport anytime to activate it for another full year.",
      },
      callToActionBox: {
        title: "Your ESG Passport expired",
        text: "Your company's Pulse ESG Passport has expired. Don't forget to retake your ESG Passport to activate it for another full year. By renewing your passport you can update your answers and improve your ESG score to impress your clients.",
        button: "Renew Passport",
      },
    },
  };
}

export function useGetPassport() {
  const { openModal, closeModal } = useContext(ModalContext);

  return useCallback(
    function getPassport(params?: {
      modalBodyProps?: Pick<SimpleModalBodyProps, "hideCloseButton">;
      modalOptions?: ModalOptions;
    }) {
      openModal(
        <GetPassportModal
          modalProps={params?.modalBodyProps}
          onResponseCreated={() => {
            closeModal();
          }}
        />,
        {
          ...params?.modalOptions,
        }
      );
    },
    [closeModal, openModal]
  );
}

export function useRetakePassport() {
  const { openModal, closeModal } = useContext(ModalContext);

  return useCallback(
    function getPassport() {
      openModal(
        <WelcomePassportModal
          onCancel={closeModal}
          onNext={() => {
            openModal(<GetPassportModal onResponseCreated={closeModal} />, {
              keepHistory: true,
            });
          }}
          title="Great news, you can retake your Pulse Market ESG Passport."
          text="Ready to retake your Pulse Market ESG Passport? Don't worry all your previous answers will be archived, so you can always go back for reference."
        />
      );
    },
    [closeModal, openModal]
  );
}

export function useRenewPassport() {
  const { openModal, closeModal } = useContext(ModalContext);

  return useCallback(
    function getPassport() {
      openModal(
        <WelcomePassportModal
          onCancel={closeModal}
          onNext={() => {
            openModal(<GetPassportModal onResponseCreated={closeModal} />, {
              keepHistory: true,
            });
          }}
          title="Great news, you can renew your Pulse Market ESG Passport before it expires."
          text="Ready to renew your ESG Passport? Let's get started! You will be notified when your updated report and passport are available. Don't worry all your previous answers will be archived, so you can always go back for reference."
        />
      );
    },
    [closeModal, openModal]
  );
}

export function useOpenInviteToPassportModal() {
  const { openModal } = useContext(ModalContext);

  return useCallback(
    function getPassport() {
      openModal(
        <SimpleModalBody>
          <PassportInvitationSender />
        </SimpleModalBody>,
        { props: { maxWidth: "md" } }
      );
    },
    [openModal]
  );
}

export function mapResponseWorkflowStatus(
  status?:
    | RfpStatus
    | DynamicRfpResponseStatus
    | PassportStatus
    | CSRDDocumentStatus
) {
  return status === PassportStatus.CloseToExpire
    ? PassportStatus.Completed
    : status;
}
