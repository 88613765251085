import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/ui/Button";
import AddToList from "components/vendorlists/AddToList";
import ModalContext from "contexts/ModalContext";
import CompanyInfoRow from "pages/MySuppliersPage/CompanyInfoRow";
import { CompanyCardDto, Pair } from "shared/model";
import { SelectableComponentProps } from "shared/utils/componentTypes";

type CompanyRowProps = {
  company: CompanyCardDto;
} & SelectableComponentProps<CompanyCardDto>;

export default function CompanyRow({
  company,
  onToggleSelect,
  selected,
  disabled,
  disabledReason,
}: CompanyRowProps) {
  const { openModal, closeModal } = useContext(ModalContext);
  const history = useHistory();

  const openAddToListModal = useCallback<
    ({ company }: { company: Pair }) => void
  >(
    ({ company }: { company: Pair }) => {
      openModal(
        <AddToList
          company={company}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  return (
    <CompanyInfoRow
      onToggleSelect={onToggleSelect}
      selected={selected}
      name={company.name}
      country={company.country}
      avatar={company.avatar}
      city={company.city}
      disabled={typeof disabled === "function" ? disabled(company) : disabled}
      disabledReason={disabledReason}
      rightSide={
        <>
          <Button
            variant="normal"
            color="orange"
            onClick={() => {
              openAddToListModal({
                company: { id: company.id, name: company.name },
              });
            }}
          >
            Add to list
          </Button>
        </>
      }
      kebabMenu={{
        actions: [
          {
            text: "View Pulse Page",
            onClick: () => {
              history.push(`/pulsepage/${company.id}`);
            },
          },
          {
            text: "Add to List",
            onClick: () => {
              openAddToListModal({ company });
            },
          },
        ],
      }}
    />
  );
}
