export enum AuditActionType {
  PassportCreated = "PassportCreated",
  PassportPdfSaved = "PassportPdfSaved",
  PassportInvitationSent = "PassportInvitationSent",
  PassportInvitationResent = "PassportInvitationResent",
  CompanyMemberInvited = "CompanyMemberInvited",
  CompanyMemberReInvited = "CompanyMemberReInvited",
  CompanyMemberJoined = "CompanyMemberJoined",
  UserRoleAdded = "UserRoleAdded",
  UserRoleUpdated = "UserRoleUpdated",
  UserRoleRemoved = "UserRoleRemoved",
  RfpCreated = "RfpCreated",
  RfpRetrieved = "RfpRetrieved",
  RfpListRetrieved = "RfpListRetrieved",
  RfpUpdated = "RfpUpdated",
  RfpDeleted = "RfpDeleted",
  /** todo unused */
  RfpCloned = "RfpCloned",
  RfpEvaluationTypeChanged = "RfpEvaluationTypeChanged",
  RfpScoreSettingsCreated = "RfpScoreSettingsCreated",
  RfpScoreSettingsUpdated = "RfpScoreSettingsUpdated",
  RfpScoreSettingsRemoved = "RfpScoreSettingsRemoved",
  RfpScoreSettingsSet = "RfpScoreSettingsSet",
  RfpSubmitted = "RfpSubmitted",
  RfpWinnerSelected = "RfpWinnerSelected",
  RfpModuleAdded = "RfpModuleAdded",
  RfpModuleRemoved = "RfpModuleRemoved",
  RfpRecipientsUpdated = "RfpRecipientsUpdated",
  RfpStatusUpdated = "RfpStatusUpdated",
  RfpResponseAnswerUpdated = "RfpResponseAnswerUpdated",
  RfpResponseAnswerRemoved = "RfpResponseAnswerRemoved",
  RfpResponseAnswerAssetRemoved = "RfpResponseAnswerAssetRemoved",
  RfpResponseAnswerAssetUploaded = "RfpResponseAnswerAssetUploaded",
  RfpResponseAnswerAssetCloned = "RfpResponseAnswerAssetCloned",
  RfpResponseAssetCloned = "RfpResponseAssetCloned",
  AttachmentUploaded = "AttachmentUploaded",
  RfpAssetsCloned = "RfpAssetsCloned",
  RfpAssetRemoved = "RfpAssetRemoved",
  RfpModuleCloned = "RfpModuleCloned",
  RfpModulesCloned = "RfpModulesCloned",
  AssetRetrieved = "AssetRetrieved",
  AssetUpdated = "AssetUpdated",
  AssetAdded = "AssetAdded",
  AssetMoved = "AssetMoved",
  AssetRemoved = "AssetRemoved",
  AssetRenamed = "AssetRenamed",
  AssetListRetrieved = "AssetListRetrieved",
  AssetCloned = "AssetCloned",
  AssetTypeSet = "AssetTypeSet",
  AssetBlobUrlRetrieved = "AssetBlobUrlRetrieved",
  AssetFileItemRetrieved = "AssetFileItemRetrieved",
  CompanyRetrieved = "CompanyRetrieved",
  CompanyListRetrieved = "CompanyListRetrieved",
  CompanyUpdated = "CompanyUpdated",
  CompanyESGInvitationUpdated = "CompanyESGInvitationUpdated",
  CompanyListUpdated = "CompanyListUpdated",
  CompanyListRenamed = "CompanyListRenamed",
  CompanyListRemoved = "CompanyListRemoved",
  CompanyListCompanyRemoved = "CompanyListCompanyRemoved",
  CompanyListAdded = "CompanyListAdded",
  CompanyListCompanyAdded = "CompanyListCompanyAdded",
  FeedbackSent = "FeedbackSent",
  FolderRetrieved = "FolderRetrieved",
  FolderItemsListRetrieved = "FolderItemsListRetrieved",
  FolderAdded = "FolderAdded",
  FolderUpdated = "FolderUpdated",
  FolderRemoved = "FolderRemoved",
  FolderRenamed = "FolderRenamed",
  FolderMoved = "FolderMoved",
  FileUploaded = "FileUploaded",
  FileDownloaded = "FileDownloaded",
  QuestionnaireAdded = "QuestionnaireAdded",
  QuestionnaireRemoved = "QuestionnaireRemoved",
  QuestionnaireCloned = "QuestionnaireCloned",
  QuestionnairesCloned = "QuestionnairesCloned",
  RfpModuleUpdated = "RfpModuleUpdated",
  CompanyPassportAddedToCompany = "CompanyPassportAddedToCompany",
  PaymentValidated = "PaymentValidated",
  PaymentExecuted = "PaymentExecuted",
  PaymentRegistered = "PaymentRegistered",
  ProductOrServiceRetrieved = "ProductOrServiceRetrieved",
  ProductOrServiceListRetrieved = "ProductOrServiceListRetrieved",
  ProductOrServiceListFromCompanyRetrieved = "ProductOrServiceListFromCompanyRetrieved",
  ProductRemoved = "ProductRemoved",
  ProductAdded = "ProductAdded",
  ProductsAdded = "ProductsAdded",
  ProductUpdated = "ProductUpdated",
  QuestionUnformatedListRetrieved = "QuestionUnformatedListRetrieved",
  QuestionUpdated = "QuestionUpdated",
  QuestionCloned = "QuestionCloned",
  QuestionsCloned = "QuestionsCloned",
  QuestionnaireUpdated = "QuestionnaireUpdated",
  QuestionAdded = "QuestionAdded",
  QuestionRemoved = "QuestionRemoved",
  QuestionMoved = "QuestionMoved",
  InvitationRetrieved = "InvitationRetrieved",
  RfpInvitationListRetrieved = "RfpInvitationListRetrieved",
  RfpInvitationRetrieved = "RfpInvitationRetrieved",
  RfpInvitationAdded = "RfpInvitationAdded",
  RfpInvitationAnswered = "RfpInvitationAnswered",
  RfpResponseUpdated = "RfpResponseUpdated",
  AnswerAdded = "AnswerAdded",
  SignUp = "SignUp",
  UserLogin = "UserLoggedIn",
  UserUpdated = "UserUpdated",
  UserCompanyRoleCreated = "UserCompanyRoleCreated",
  UserCompanyRoleRemoved = "UserCompanyRoleRemoved",
  UserCompanyRoleUpdated = "UserCompanyRoleUpdated",
  DataRoomRetrieved = "DataRoomRetrieved",
  DataRoomAdded = "DataRoomAdded",
  DataRoomUpdated = "DataRoomUpdated",
  DataRoomDeleted = "DataRoomDeleted",
  DataRoomVideoAdded = "DataRoomVideoAdded",
  DataRoomVideoUpdated = "DataRoomVideoUpdated",
  DataRoomVideoDeleted = "DataRoomVideoDeleted",
  DataRoomAssetCloned = "DataRoomAssetCloned",
  DataRoomListRetrieved = "DataRoomListRetrieved",
  GroupAdded = "GroupAdded",
  GroupUpdated = "GroupUpdated",
  GroupDeleted = "GroupDeleted",
  GroupInvitationStatusUpdated = "GroupInvitationStatusUpdated",
  GroupMemberInvited = "GroupMemberInvited",
  GroupMemberAdded = "GroupMemberAdded",
  GroupJoinRequestSent = "GroupJoinRequestSent",
  GroupMemberLeft = "GroupMemberLeft",
  GroupMediaItemAdded = "GroupMediaItemAdded",
  GroupMediaItemUpdated = "GroupMediaItemUpdated",
  GroupMediaItemRemoved = "GroupMediaItemRemoved",
  GroupMediaItemApprovalStatusChanged = "GroupMediaItemApprovalStatusChanged",
  SupplierCreated = "SupplierCreated",
  SuppliersCreated = "SuppliersCreated",
  SupplierRetrieved = "SupplierRetrieved",
  SupplierListRetrieved = "SupplierListRetrieved",
  SupplierUpdated = "SupplierUpdated",
  SupplierDeleted = "SupplierDeleted",
  SupplierContractCreated = "SupplierContractCreated",
  SupplierContractUpdated = "SupplierContractUpdated",
  SupplierContractDeleted = "SupplierContractDeleted",
  SupplierContractAttachmentAdded = "SupplierContractAttachmentAdded",
  SupplierContractAttachmentDeleted = "SupplierContractAttachmentDeleted",
  SupplierContractAttachmentsCloned = "SupplierContractAttachmentsCloned",
  SupplierAttachmentAdded = "SupplierAttachmentAdded",
  SupplierAttachmentsCloned = "SupplierAttachmentsCloned",
  PostCreated = "PostCreated",
  PostUpdated = "PostUpdated",
  PostLiked = "PostLiked",
  PostDeleted = "PostDeleted",
  PostAttachmentUploaded = "PostAttachmentUploaded",
  PostAttachmentsCloned = "PostAttachmentsCloned",
  PostMediaItemAdded = "PostMediaItemAdded",
  PostMediaItemUpdated = "PostMediaItemUpdated",
  PostMediaItemRemoved = "PostMediaItemRemoved",
  CommentCreated = "CommentCreated",
  CommentUpdated = "CommentUpdated",
  CommentDeleted = "CommentDeleted",
  UserActivity = "UserActivity",
  RfpAnswerMessagesSent = "RfpAnswerMessagesSent",
  ImageUploaded = "ImageUploaded",
  AttributeTypeCreated = "AttributeTypeCreated",
  AttributeTypeUpdated = "AttributeTypeUpdated",
  AttributeTypeDeleted = "AttributeTypeDeleted",
  AttributeSet = "AttributeSet",
  SupplierProductCreated = "SupplierProductCreated",
  SupplierProductUpdated = "SupplierProductUpdated",
  SupplierProductAttachmentAdded = "SupplierProductAttachmentAdded",
  SupplierProductAttachmentDeleted = "SupplierProductAttachmentDeleted",
  SupplierProductAttachmentsCloned = "SupplierProductAttachmentsCloned",
  CertificateCreated = "CertificateCreated",
  CertificateUpdated = "CertificateUpdated",
  CertificateDeleted = "CertificateDeleted",
  MatrixUpdated = "MatrixUpdated",
  MatrixCreated = "MatrixCreated",
  MatrixRetrieved = "MatrixRetrieved",
  MatrixDataRetrieved = "MatrixDataRetrieved",
  MatrixDeleted = "MatrixDeleted",
  MatrixColumnInserted = "MatrixColumnInserted",
  MatrixColumnUpdated = "MatrixColumnUpdated",
  MatrixColumnDeleted = "MatrixColumnDeleted",
  MatrixCellInserted = "MatrixCellInserted",
  MatrixCellUpdated = "MatrixCellUpdated",
  MatrixCellDeleted = "MatrixCellDeleted",
  MatrixRowInserted = "MatrixRowInserted",
  MatrixRowUpdated = "MatrixRowUpdated",
  MatrixRowDeleted = "MatrixRowDeleted",
  MatrixCloned = "MatrixCloned",
  LookupTableCreated = "LookupTableCreated",
  LookupTableUpdated = "LookupTableUpdated",
  LookupRowCreated = "LookupRowCreated",
  LookupRowUpdated = "LookupRowUpdated",
  LookupRowDeleted = "LookupRowDeleted",
  SupplierGroupCreated = "SupplierGroupCreated",
  SupplierGroupsRetrieved = "SupplierGroupsRetrieved",
  SupplierGroupUpdated = "SupplierGroupUpdated",
  SupplierGroupDeleted = "SupplierGroupDeleted",
  QuestionsImported = "QuestionsImported",
  // CSRD related audit log items
  CSRDAssessmentRowListRetrieved = "CSRDAssessmentRowListRetrieved",
  CSRDAssessmentRowAdded = "CSRDAssessmentRowAdded",
  CSRDAssessmentRowUpdated = "CSRDAssessmentRowUpdated",
  CSRDAssessmentRowDeleted = "CSRDAssessmentRowDeleted",
  //Question labels
  QuestionLabelCreated = "QuestionLabelCreated",
  QuestionLabelUpdated = "QuestionLabelUpdated",
  QuestionLabelDeleted = "QuestionLabelDeleted",
  LabelsAddedToQuestions = "LabelsAddedToQuestions",
  LabelRemovedFromQuestions = "LabelRemovedFromQuestions",
  AttachmentsClonedToFolder = "AttachmentsClonedToFolder",
  DashboardItemAdded = "DashboardItemAdded",
  DashboardItemUpdated = "DashboardItemUpdated",
  DashboardItemDeleted = "DashboardItemDeleted",
  // Reports
  RfpExcelReportRetrieved = "RfpExcelReportRetrieved",
  RfpWordReportRetrieved = "RfpWordReportRetrieved",
  SupplierReportRetrieved = "SupplierReportRetrieved",
  ProductReportRetrieved = "ProductReportRetrieved",
  MatrixDataReportRetrieved = "MatrixDataReportRetrieved",
  CSRDMaterialityReportRetrieved = "CSRDMaterialityReportRetrieved",
}
