import React, { Suspense } from "react";
import { Route } from "react-router-dom";

import { RFPType, ROUTES } from "@pulsemarket/constants";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import OnboardedRoute from "components/authentication/OnboardedRoute";
import { Loading } from "components/FullScreenLoading";
import { VendorSearchSideMenuItem } from "components/VendorSearch/Sidebar/SideBar";
import { useGetRegulations } from "hooks/queries/regulationHooks";
import { useCheckInvitations } from "hooks/useCheckInvitations";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import { ViewerType } from "pages/RFPEditorPage/utils";
import { StandardRFPType } from "shared/model";
import lazyRetry from "util/lazyRetry";

const AboutPage = React.lazy(() =>
  lazyRetry(() => import("pages/About"), "About")
);
const AssetRepositoryPage = React.lazy(() =>
  lazyRetry(() => import("pages/asset_repository"), "asset_repository")
);
const CompanyInfoPage = React.lazy(() =>
  lazyRetry(() => import("pages/CompanyInfoPage"), "CompanyInfoPage")
);
const CompanyPage = React.lazy(() =>
  lazyRetry(() => import("pages/CompanyPage"), "CompanyPage")
);
const Dashboard = React.lazy(() =>
  lazyRetry(() => import("pages/Dashboard"), "Dashboard")
);
const DataRoom = React.lazy(() =>
  lazyRetry(() => import("pages/DataRoom"), "DataRoom")
);
const DataManagement = React.lazy(() =>
  lazyRetry(() => import("pages/DataManagement"), "DataManagement")
);
const MyDataRooms = React.lazy(() =>
  lazyRetry(() => import("pages/MyDataRooms"), "MyDataRooms")
);
const MySuppliersPage = React.lazy(() =>
  lazyRetry(() => import("pages/MySuppliersPage"), "MySuppliersPage")
);
const NotificationsPage = React.lazy(() =>
  lazyRetry(() => import("pages/NotificationsPage"), "NotificationsPage")
);

const ProfileInfoPage = React.lazy(() =>
  lazyRetry(() => import("pages/ProfileInfoPage"), "ProfileInfoPage")
);
const GroupPage = React.lazy(() =>
  lazyRetry(() => import("pages/PulseGroup/GroupPage"), "GroupPage")
);
const Groups = React.lazy(() =>
  lazyRetry(() => import("pages/PulseGroup/groups/Groups"), "Groups")
);
const RFPEditorPage = React.lazy(() =>
  lazyRetry(() => import("pages/RFPEditorPage"), "RFPEditorPage")
);
const RFPPage = React.lazy(() =>
  lazyRetry(() => import("pages/RFPPage"), "RFPPage")
);
const RFPResponsesPage = React.lazy(() =>
  lazyRetry(() => import("pages/RFPResponses"), "RFPResponses")
);
const SupplierDetailsPage = React.lazy(() =>
  lazyRetry(() => import("pages/SupplierDetailsPage"), "SupplierDetailsPage")
);
const PassportsPage = React.lazy(() =>
  lazyRetry(() => import("pages/Passports/PassportsPage"), "PassportsPage")
);
const RegulationsPage = React.lazy(() =>
  lazyRetry(
    () => import("pages/Regulations/RegulationsPage"),
    "RegulationsPage"
  )
);
const VendorSearchPage = React.lazy(() =>
  lazyRetry(() => import("pages/VendorSearch"), "VendorSearch")
);

export default function AppRoutes() {
  const { data: dataRoomEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataroom,
  });
  const { data: dataManagementEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.dataManagement,
  });

  const { data: suppliersEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.mySuppliers,
  });

  const { data: regulations } = useGetRegulations();

  const passportEnabled = usePassportFeatureEnabled();

  const { loadingMessage } = useCheckInvitations();

  if (loadingMessage) {
    return <Loading message={loadingMessage} />;
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <OnboardedRoute path="/groups/" exact>
          <Groups />
        </OnboardedRoute>
        <OnboardedRoute path="/vendorsearch/suppliersearch" exact>
          <VendorSearchPage tab={VendorSearchSideMenuItem.Vendors} />
        </OnboardedRoute>
        <OnboardedRoute path="/discovergroups" exact>
          <VendorSearchPage tab={VendorSearchSideMenuItem.Groups} />
        </OnboardedRoute>

        <OnboardedRoute path={ROUTES.RFP_MANAGEMENT_FOR_BUYERS} exact>
          <RFPPage />
        </OnboardedRoute>
        <OnboardedRoute path={ROUTES.RFP_MANAGEMENT_FOR_SUPPLIERS} exact>
          <RFPResponsesPage />
        </OnboardedRoute>

        {/* Regulation questionnaire URLs */}

        <OnboardedRoute
          path={ROUTES.REGULATION_QUESTIONNAIRE({
            questionnaireId: ":rfpId",
          })}
          exact
        >
          <RFPEditorPage rfpType={RFPType.Regulation} />
        </OnboardedRoute>

        {/* Invitaton without responseId */}
        <OnboardedRoute
          path={ROUTES.REGULATION_QUESTIONNAIRE({
            questionnaireId: ":rfpId",
            isResponse: true,
          })}
          exact
        >
          <RFPEditorPage
            viewerType={ViewerType.Supplier}
            rfpType={RFPType.Regulation}
          />
        </OnboardedRoute>

        {/* ResponseId exist */}
        <OnboardedRoute
          path={ROUTES.REGULATION_QUESTIONNAIRE({
            questionnaireId: ":rfpId",
            isResponse: true,
            responseId: ":responseId",
          })}
          exact
        >
          <RFPEditorPage
            viewerType={ViewerType.Supplier}
            rfpType={RFPType.Regulation}
          />
        </OnboardedRoute>

        {Object.values(StandardRFPType).map((t) => {
          const type = t.toLowerCase();

          const baseBuyerPath = `/${type}`;
          const supplierBasePath = `/${type}responses`;

          const buyerDocumentPath = `/${type}/:rfpId`;
          const supplierInvitationPath = `/${type}responses/${type}/:rfpId/invitation/:invitationId?`;
          const supplierDocumentPath = `/${type}responses/${type}/:rfpId/response/:responseId?`;

          return (
            <React.Fragment key={t}>
              <OnboardedRoute path={baseBuyerPath} exact>
                <RFPPage rfpType={RFPType[t]} />
              </OnboardedRoute>

              <OnboardedRoute path={supplierBasePath} exact>
                <RFPResponsesPage rfpType={RFPType[t]} />
              </OnboardedRoute>

              <OnboardedRoute path={buyerDocumentPath}>
                <RFPEditorPage rfpType={RFPType[t]} />
              </OnboardedRoute>

              <OnboardedRoute path={supplierInvitationPath} exact>
                <RFPEditorPage
                  viewerType={ViewerType.Supplier}
                  rfpType={RFPType[t]}
                />
              </OnboardedRoute>

              <OnboardedRoute path={supplierDocumentPath} exact>
                <RFPEditorPage
                  viewerType={ViewerType.Supplier}
                  rfpType={RFPType[t]}
                />
              </OnboardedRoute>
            </React.Fragment>
          );
        })}

        {passportEnabled && (
          <OnboardedRoute path={ROUTES.PASSPORT_MANAGEMENT}>
            <PassportsPage />
          </OnboardedRoute>
        )}

        {regulations?.map((regulation) => (
          <OnboardedRoute
            key={regulation.id}
            // path={`/${regulation.name}`}
            path={ROUTES.REGULATIONS({ regulation }).Root}
          >
            <RegulationsPage regulation={regulation} />
          </OnboardedRoute>
        ))}

        <OnboardedRoute path="/vendorsearch/companylist/:companyListId?" exact>
          <VendorSearchPage tab={VendorSearchSideMenuItem.CompanyLists} />
        </OnboardedRoute>
        <OnboardedRoute path="/company/edit">
          <CompanyInfoPage />
        </OnboardedRoute>
        <OnboardedRoute path="/profile/edit">
          <ProfileInfoPage />
        </OnboardedRoute>
        <Route path="/pulsepage/:companyId" exact>
          <CompanyPage />
        </Route>
        <OnboardedRoute path="/company/:companyId/supplier-search" exact>
          <CompanyPage />
        </OnboardedRoute>
        <OnboardedRoute path="/about" exact>
          <AboutPage />
        </OnboardedRoute>
        <OnboardedRoute path="/assets/:folderId" exact>
          <AssetRepositoryPage />
        </OnboardedRoute>

        <OnboardedRoute path="/notifications">
          <NotificationsPage />
        </OnboardedRoute>

        {suppliersEnabled && (
          <>
            <OnboardedRoute path={ROUTES.MY_SUPPLIERS} exact>
              <MySuppliersPage />
            </OnboardedRoute>
            <OnboardedRoute path={`${ROUTES.MY_SUPPLIERS}/:id`}>
              <SupplierDetailsPage />
            </OnboardedRoute>
          </>
        )}

        <Route path="/groups/:groupId" exact>
          <GroupPage />
        </Route>
        {dataRoomEnabled && (
          <>
            <Route path="/datarooms/:id/:folderId?" exact>
              <DataRoom />
            </Route>
            <OnboardedRoute path="/datarooms" exact>
              <MyDataRooms />
            </OnboardedRoute>
          </>
        )}

        {dataManagementEnabled && (
          <OnboardedRoute path={ROUTES.DATA_MANAGEMENT.root}>
            <DataManagement />
          </OnboardedRoute>
        )}

        <OnboardedRoute path="/" exact>
          <Dashboard />
        </OnboardedRoute>

        {/* TODO: Add not Found page design */}
        <Route path="*" />
      </Suspense>
    </>
  );
}
